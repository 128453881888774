import Moon from './components/moonApp';
import React from 'react';
import ReactDOM from 'react-dom/client';
const root = ReactDOM.createRoot(document.getElementById('root'));
var urlBeg = 'https://svs.gsfc.nasa.gov/vis/a000000/a005000/a005048/frames/730x730_1x1_30p/moon.'


const d = new Date();
let time = Math.round((d.getTime())/3.6e6);
const e = new Date("January 1 2023 00:00");
let time2 = Math.round((e.getTime())/3.6e6);
let time3 = time-time2
var urlMid = time3


if(String(urlMid).charAt(0) !== '0') {
  urlMid = '0'+time3
}

var urlEnd = '.jpg'
var url = urlBeg+urlMid+urlEnd
window.alert(url)
function callMoon(){
  return(root.render(
    <React.StrictMode>
      <Moon />
    </React.StrictMode>))
}

function handleSubmit(e) {
  e.preventDefault();
  return(
  callMoon()
  )
}
function App() {
  
 
  


	return( 
    
    <html lang="en">
      <head>
        <title>Tanessa Dreams</title>
        <meta charset="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/> 
        <link rel="icon" href="tanessadreams.ico"/>
        <link rel="stylesheet" href="https://use.typekit.net/qop2twf.css"/> 
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>    
      </head>
      <body>
        <h1>M<p1> Y </p1>M<p1> O O N </p1>D<p1> R E A M S</p1></h1>		
       
        <img src = {url} alt="moon" width= '170' height='370' border='120'></img>
      
   
   
        <button onClick={handleSubmit}>
        <button type="submit">Submit</button>
        </button>
        
        
        
      </body>
      <script> 
    </script>
      <style global jsx>{`
        body {
          background: black;
          font-size:160px;
        }
        quest {
          color:grey; 
          font-family:Verdana;
          white-space: nowrap;  
          text-align:center;
          position: relative;
          top: -60px;
        }
        moon {
        fontFamily:'Verdana',
                whiteSpace: 'nowrap',  
                top: '280px',
                fontSize: '28px',
                color: 'grey',
                textAlign:'center',
                paddingLeft: '101',
                
                position: 'relative'
        }
        h1 {
          color:grey; 
          background-color: white;
          text-align: center;
          font-family: brother-1816-printed, sans-serif;
          font-weight: 20;
          font-style: italic;
          font-size: 50%;
          font-color: white;
          border-radius: 225px 15px 225px 15px/15px 225px 15px 255px;
          position: relative;
          white-space: nowrap;
        }
        p1 {
          color:grey; 
          background-color: white;
          text-align: center;
          font-size: 80%;
          font-family: brother-1816-printed, sans-serif;
          font-weight: 20;
          white-space: nowrap;
          font-style: normal;
          font-color: white;
          padding: 1px 7px;
          border-radius: 225px 15px 225px 15px/15px 225px 15px 255px;
        }
       
        
        img{
          position:relative;
          width: 20%;
          height: 40%;
          border: none;
          top: -30px;
        }`
      }</style> 
    </html>
  );
};
export default App;