
export default function Answer(props) {
    
    var ButFontSize = props.width*(0.05)
    var ButLeft = props.width*(0.38)
    var ButTop = -300
    const makeFun = props.makeFun
    if(props.t === 'How did you feel before you went to sleep?') {
        return(
            <answer>
                <button1 onClick={makeFun(props.events)}>{props.events}</button1> 
                <button2 onClick={makeFun(props.events2)}>{props.events2}</button2>
                <button3 onClick={makeFun(props.events3)}>{props.events3}</button3>
                <button4 onClick={makeFun(props.events4)}>{props.events4}</button4>
                <button5 onClick={makeFun(props.events5)}>{props.events5}</button5>
                <button6 onClick={makeFun(props.events6)}>{props.events6}</button6>
                <buttonNOA1 onClick={makeFun(props.NOA1)}>{props.NOA1}</buttonNOA1>
                <buttonN onClick={makeFun(props.next1)}>{props.next1}</buttonN>
            </answer> 
        )
    }
    if(props.t === 'What did you do before you fell asleep?') {
        return(
            <answer>
                <buttonb1 onClick={makeFun(props.events)}>{props.events}</buttonb1>
                <buttonb2 onClick={makeFun(props.events2)}>{props.events2}</buttonb2>
                <buttonb3 onClick={makeFun(props.events3)}>{props.events3}</buttonb3>
                <buttonb4 onClick={makeFun(props.events4)}>{props.events4}</buttonb4>
                <buttonb5 onClick={makeFun(props.events5)}>{props.events5}</buttonb5>
                <buttonb6 onClick={makeFun(props.events6)}>{props.events6}</buttonb6>
                <buttonNOA2 onClick={makeFun(props.NOA2)}>{props.NOA2}</buttonNOA2>
                <buttonN2 onClick={makeFun(props.next1)}>{props.next1}</buttonN2>
            </answer> 
        )
    }
    if(props.t === 'How did you feel upon waking?') {
        return(
            <answer>
                <buttonc1 onClick={makeFun(props.events)}>{props.events}</buttonc1>
                <buttonc2 onClick={makeFun(props.events2)}>{props.events2}</buttonc2>
                <buttonc3 onClick={makeFun(props.events3)}>{props.events3}</buttonc3>
                <buttonc4 onClick={makeFun(props.events4)}>{props.events4}</buttonc4>
                <buttonc5 onClick={makeFun(props.events5)}>{props.events5}</buttonc5>
                <buttonc6 onClick={makeFun(props.events6)}>{props.events6}</buttonc6>
                <buttonNOA3 onClick={makeFun(props.NOA3)}>{props.NOA3}</buttonNOA3>
                <buttonN3 onClick={makeFun(props.next1)}>{props.next1}</buttonN3>
            </answer> 
        )
    }
    if(props.t === 'Have you had this dream before?') {
       
        return(
            <answer>
                <buttond1 onClick={makeFun(props.events)}>{props.events}</buttond1>
                <buttond2 onClick={makeFun(props.events2)}>{props.events2}</buttond2>
                <buttonN4 onClick={makeFun(props.next1)}>{props.next1}</buttonN4> 
            </answer>   
        )
    }
        if(props.t === 'Did you enjoy this dream?') {
            return(
                <answer>
                    <buttone1 onClick={makeFun(props.events)}>{props.events}</buttone1>
                    <buttone2 onClick={makeFun(props.events2)}>{props.events2}</buttone2>
                    <buttone3 onClick={makeFun(props.events3)}>{props.events3}</buttone3>
                    <buttonN4 onClick={makeFun(props.next1)}>{props.next1}</buttonN4> 
                </answer>  
            )
        }
            if(props.t === 'Did you experience any of these in your dream?') {
                return(
                    <answer>
                        <buttonf1 onClick={makeFun(props.events)}>{props.events}</buttonf1>
                        <buttonf2 onClick={makeFun(props.events2)}>{props.events2}</buttonf2>
                        <buttonf3 onClick={makeFun(props.events3)}>{props.events3}</buttonf3>
                        <buttonf4 onClick={makeFun(props.events4)}>{props.events4}</buttonf4>
                        <buttonf5 onClick={makeFun(props.events5)}>{props.events5}</buttonf5>
                        <buttonf6 onClick={makeFun(props.events6)}>{props.events6}</buttonf6>
                        <buttonNOA4 onClick={makeFun(props.NOA4)}>{props.NOA4}</buttonNOA4>
                        <buttonN6 onClick={makeFun(props.next1)}>{props.next1}</buttonN6> 
                    </answer>    
                )
            }
                if(props.t === 'Results') {
                    return(   
                        <answer style={{
                            width: '20em',
                            lineHeight: '2em',
                            color:'white',
                            whiteSpace:'normal',
                            textAlign: 'center',
                            fontSize: `${ButFontSize-2}%`,
                            left: `${(ButLeft+20)}px`,
                            top: `${ButTop}px`,
                            position: 'absolute'
                        }}>{props.events}</answer>
                    )
                }
}
