export default function Apply(props) { 
    var font = (31 - (0.024*(1280-props.width)))
    if(props.apply === 'How did you feel before you went to sleep?') {
        return(
            <apply style={{
                fontFamily:'Verdana',
                whiteSpace: 'nowrap',  
                top: '-280px',
                fontSize: `${font}px`,
                color: 'grey',
                textAlign:'center',
                paddingLeft: `${11}em`,
                left: `-10%`,
                position: 'relative'
            }}>Choose all that apply</apply>
        )
    }
    if(props.apply === props.B) {
        return(
            <apply style={{
                fontFamily:'Verdana',
                whiteSpace: 'nowrap',  
                top: '-280px',
                fontSize: `${font}px`,
                color: 'grey',
                textAlign:'center',
                paddingLeft: `${11}em`,
                left: `-10%`,
                position: 'relative'
            }}>Choose all that apply</apply> 
        )
    }   
    if(props.apply === props.C) {
        return(
            <apply style={{
                fontFamily:'Verdana',
                whiteSpace: 'nowrap',  
                top: '-280px',
                fontSize: `${font}px`,
                color: 'grey',
                textAlign:'center',
                paddingLeft: `${11}em`,
                left: `-10%`,
                position: 'relative'
            }}>Choose all that apply</apply>
        )
    }
    if(props.apply === props.D) {
        
        return(
            <apply style={{
                fontFamily:'Verdana',
                whiteSpace: 'nowrap',  
                top: '-280px',
                fontSize: `${font}px`,
                color: 'grey',
                textAlign:'center',
                paddingLeft: `${11}em`,
                left: `-10%`,
                position: 'relative'
            }}></apply>
        )
    }
    if(props.apply === props.E) {
        
        return(
            <apply style={{
                fontFamily:'Verdana',
                whiteSpace: 'nowrap',  
                top: '-280px',
                fontSize: `${font}px`,
                color: 'grey',
                textAlign:'center',
                paddingLeft: `${11}em`,
                left: `-10%`,
                position: 'relative'
            }}></apply>
        )
    }
    if(props.apply === props.F) {
       
        return(
            <apply style={{
                fontFamily:'Verdana',
                whiteSpace: 'nowrap',  
                top: '-280px',
                fontSize: `${font}px`,
                color: 'grey',
                textAlign:'center',
                paddingLeft: `${11}em`,
                left: `-10%`,
                position: 'relative'
            }}>Choose all that apply</apply>
        )
    }
    if(props.apply === props.G) {
        return(
            <apply style={{
                fontFamily:'Verdana',
                whiteSpace: 'nowrap',  
                top: '-280px',
                fontSize: `${font}px`,
                color: 'grey',
                textAlign:'center',
                paddingLeft: `${11}em`,
                left: `-10%`,
                position: 'relative'
            }}></apply>
        )
    }
}