import Question from './question';
import Answer from './answer';
import React, { useState } from 'react';
import Apply from './apply';


function Moon(){
  
    //questions
    
    
    var Home = ''
    var A = 'How did you feel before you went to sleep?'
    var B = 'What did you do before you fell asleep?'
    var C = 'How did you feel upon waking?'
    var D = 'Have you had this dream before?'
    var E = 'Did you enjoy this dream?'
    var F = 'Did you experience any of these in your dream?'
    var G = 'Results'
    //A answers
    var A1 = 'Happy'
    var A2 = 'Sad'
    var A3 = 'Loved'
    var A4 = 'Afraid'
    var A5 = 'Stressed'
    var A6 = 'Angry'
    var A7 = 'None of the above'
    //B answers
    var B1 = 'Watched TV/Read'
    var B2 = 'Ate a snack or meal'
    var B3 = 'Alcohol/drugs'
    var B4 = 'Took melatonin/ sleep remedy'
    var B5 = 'Prayed/meditated'
    var B6 = 'Hanky panky'
    var B7 = 'None of the above'
    //C answers
    var C1 = 'Happy '
    var C2 = 'Sad '
    var C3 = 'Loved '
    var C4 = 'Afraid '
    var C5 = 'Stressed '
    var C6 = 'Angry '
    var C7 = 'None of the above '
    //D answers
    var D1 = 'Yes'
    var D2 = 'No'
    //E answers
    var E1 = 'Yes '
    var E2 = 'No '
    var E3 = 'Neither'
    //F answers
    var F1 = 'Flying'
    var F2 = 'Being chased/fighting'
    var F3 = 'Falling'
    var F4 = 'Lost'
    var F5 = 'Death'
    var F6 = 'Birth'
    var F7 = '  None of the above  '
    //A responses
    var a1 = 'Honor the happiness you felt before drifting off to dream land, no matter the outcome. You deserve to be happy. '
    var a2 = 'Honor the sadness you felt before drifting off to dream land, no matter the outcome. Sadness is a beautiful way to be human. Allow yourself to feel it. '
    var a3 = 'Honor the love you felt before drifting off to dream land, no matter the outcome. Love is a feeling to hold onto and return to, regardless of your dreams and circumstances. '
    var a4 = 'Honor the fear you felt before drifting off to dream land, no matter the outcome. Life is scary. Look to your dreams as a place to practice being brave. Consider a bedtime routine that calms you. What can you change to make you feel safe? '
    var a5 = 'Honor the stress you felt before drifting off to dream land, no matter the outcome. Find the clues in your dream that guide you in what needs to change, for you to live life with ease. '
    var a6 = 'Honor the anger you felt before drifting off to dream land, no matter the outcome. If you let the sun go down on your anger, be prepared for a bumpy dream ride. Don’t hold your anger in. Perhaps, your dream will be the release you need. '
    var a7 = 'Honor the feelings—vague, unknown or clear—that you felt before drifting off to dream land, no matter the outcome. Reflect a moment on what you felt and how that energy impacted your dream. '
    //B responses
    var b1 = 'Your bedtime activities can influence your sleep and dreams. Think about what you watched or read before sleep and if your dreams reflected some version of that. '
    var b2 = 'Food can impact sleep and dreams. Look for patterns. Make choices that nurture your sleep and dreams. '
    var b3 = 'Adult substances can impact sleep and dreams. Look for patterns. Make choices that nurture your sleep and dreams. '
    var b4 = 'If you use melatonin or other sleep remedies, you probably have identified a sleep disturbance. Insomnia might be your middle name. Read about segmented sleep throughout history and cultures. Maybe some ancestral wisdom can help you access what you need to sleep more peacefully. '
    var b5 = 'Your quiet time before bed is grounding and setting the tone for insightful dreams. '
    var b6 = 'If a pre-bed workout reduces your stress and tires you out, enjoy the benefits of a little pre-dream bop. Pay attention to how it impacts your dream life. Maybe you’ll discover something fun. '
    var b7 = 'Your bedtime activities can influence your sleep and dreams. Think about what you did before sleep and if your dreams reflected some version of that. '
    //C responses
    var c1 = 'Even if you don’t recall every detail of your dream, notice how you feel as you wake up. Think of the feeling the dream gave you, not the situation you are waking up to. That waking-up-feeling means more than any symbol. What made you happy about this dream? It’s OK if you don’t know. Just know that some experience in the dream called on you to reflect on your happiness. '
    var c2 = 'Even if you don’t recall every detail of your dream, notice how you feel as you wake up. Think of the feeling the dream gave you, not the situation you are waking up to. That waking-up-feeling means more than any symbol. What made you sad about this dream? It’s OK if you don’t know. Just know that some experience in the dream called on you to reflect on your sadness. '
    var c3 = 'if you don’t recall every detail of your dream, notice how you feel as you wake up. Think of the feeling the dream gave you, not the situation you are waking up to. That waking-up-feeling means more than any symbol. What made you feel loved in this dream? It’s OK if you don’t know. Just know that some experience in the dream called on you to reflect on love. '
    var c4 = 'if you don’t recall every detail of your dream, notice how you feel as you wake up. Think of the feeling the dream gave you, not the situation you are waking up to. That waking-up-feeling means more than any symbol. What made you feel fear about this dream? It’s OK if you don’t know. Just know that some experience in the dream called on you to reflect on your fears. '
    var c5 = 'if you don’t recall every detail of your dream, notice how you feel as you wake up. Think of the feeling the dream gave you, not the situation you are waking up to. That waking-up-feeling means more than any symbol. What made you stressed about this dream? It’s OK if you don’t know. Just know that some experience in the dream called on you to reflect on your stress. '
    var c6 = 'if you don’t recall every detail of your dream, notice how you feel as you wake up. Think of the feeling the dream gave you, not the situation you are waking up to. That waking-up-feeling means more than any symbol. What made you angry about this dream? It’s OK if you don’t know. Just know that some experience in the dream called on you to reflect on your anger. '
    var c7 = 'if you don’t recall every detail of your dream, notice how you feel as you wake up. Think of the feeling the dream gave you, not the situation you are waking up to. That waking-up-feeling means more than any symbol. What made you feel this self-described way about this dream? It’s OK if you don’t know. Just know that some experience in the dream called on you to reflect on this feeling. '
    //D responses
    var d1 = 'Recurring dreams can be like a favorite song or movie, or they can haunt us for life. At its best, this dream tells a story that is a guide, a teacher, a dear old friend. Let it be a reminder of who you are and what brings you joy. On the flip side, this dream could offer healing through it’s nightmarish or uncomfortable nature. The problem in the dream could mirror a problem in your life. I have found that when we deal with our issues in waking life, the bulk of the bad dreams go away. '
    var d2 = ''
    //E responses
    var e1 = 'This dream is here to support you and speak to the things you want in life. How can you bring the essence of this experience into your life? You may not be able to fully recreate it. Just find a small way to honor the dream. '
    var e2 = 'Dreams are gifts, even when we don’t enjoy them. They let us get out our anger, prepare for danger, face our fears, etc. What a relief to wake up and know it was just a dream. This is a great time to show some gratitude for all that is good in your world. '
    var e3 = 'landed in that mysterious space where a dream is not one you love or hate. It just IS. It is likely to have some complexity, maybe a dream that is disjointed in some way, a lot wrapped up in one experience. Life is like this. It is an opportunity to take what you need from any situation and let go of what doesn’t serve you. ' 
    //F responses
    var f1 = 'I once read that flying dreams mean you want to escape. That’s only true if it’s true for you. Sometimes, a girl just wants to fly. It feels good and is empowering. Dig deep. What does that experience represent to you?'
    var f2 = 'In dreams, my feet are stuck. I can never get away. Practice being brave in dreams. In your waking life, vow to confront the aggressors the next time they chase you. The dream landscape is your playground to test your strength.'
    var f3 = 'In dreams, my feet are stuck. I can never get away. Practice being brave in dreams. In your waking life, vow to confront the aggressors the next time they chase you. The dream landscape is your playground to test your strength.'
    var f4 = 'In elementary school, kids used to say that if you had a falling dream and hit the ground, you would die. How would anyone know? I wondered. What is falling to you? A fun release? Being out of control? A theme in a movie you watched before bed? Choose your own adventure.'
    var f5 = 'In dreams, the living may die, and the dead may return to life. Many fear their death dreams are premonitions. But everyone will leave this earth eventually. See the death dream as a reminder to love the living and feel the love and redemption of those who have departed.'
    var f6 = 'If you are not expecting the arrival of new life in some form, look at the metaphor. What are you trying to birth or create in life? Reflect on the things you have already created that you are proud of.' 
    var f7 = ''
    //uses time and moon url to retrieve the current moon image 
    var urlBeg = 'https://svs.gsfc.nasa.gov/vis/a000000/a005000/a005048/frames/730x730_1x1_30p/moon.'


    const d = new Date();
    let time = Math.round((d.getTime())/3.6e6);
    const e = new Date("January 1 2023 00:00");
    let time2 = Math.round((e.getTime())/3.6e6);
    let time3 = time-time2
    var urlMid = time3
    
    
    if(String(urlMid).charAt(0) !== '0') {
      urlMid = '0'+time3
    }
    
    var urlEnd = '.jpg'
    var url = urlBeg+urlMid+urlEnd
    //list for feedback user will recieve at the end
    var feedback = [];
    //initiates parameters for style elements
    var ButMargin = 30;
    var ButFontSize = 50;
    var ButLeft = 42;
    var ButTop = -156;

   
    const [width] = React.useState(window.innerWidth)
    const [height] = React.useState(window.innerHeight)
    const [fun, setFun] = useState(A)
    const [events, setEvents] = useState(A1)
    const [events2, setEvents2] = useState(A2)
    const [events3, setEvents3] = useState(A3)
    const [events4, setEvents4] = useState(A4)
    const [events5, setEvents5] = useState(A5)
    const [events6, setEvents6] = useState(A6)
    const [events7, setEvents7] = useState(A7)
    const [moonImage] = useState(url)
    const [next1] = useState('Next')
    const [NOA1] = useState('None of the above ')
    const [NOA2] = useState('None of the above  ')
    const [NOA3] = useState(' None of the above  ')
    const [NOA4] = useState('  None of the above  ') 
    //makeFun takes in a string variable and checks which corresponding function to call onpress
    const makeFun = (a) => {
        if( a === 'Happy'){
        return(Happy)
        }
        if( a === 'Sad'){
        return(Sad) 
        }
        if( a === 'Loved'){
        return(Loved)
        }
        if( a === 'Afraid'){
        return(Afraid)
        }
        if( a === 'Stressed'){
        return(Stressed)
        }
        if( a === 'Angry'){
        return(Angry)
        }
        if( a === 'Watched TV/Read') {
        return(Watched_TV)
        }
        if( a === 'Ate a snack or meal') {
        return(Ate)
        }
        if( a === 'Took melatonin/ sleep remedy') {
        return(Remedy)
        }
        if( a ===  'Alcohol/drugs') {
        return(Drugs)
        }
        if( a === 'Prayed/meditated') {
        return(Prayed)
        }
        if( a === 'Hanky panky') {
        return(Hanky)
        }
        if( a === 'None of the above ') {
        return(NOTA1)
        }
        if( a === 'None of the above  ') {
        return(NOTA2)
        }
        if( a === ' None of the above  ') {
        return(NOTA3)
        }
        if( a === '  None of the above  ') {
        return(NOTA4)
        }
        if( a === 'Happy '){
        return(Happy2)
        }
        if( a === 'Sad '){
        return(Sad2)
        }
        if( a === 'Loved '){
        return(Loved2)
        }
        if( a === 'Afraid '){
        return(Afraid2)
        }
        if( a === 'Stressed '){
        return(Stressed2)
        }
        if( a === 'Angry '){
        return(Angry2)
        }
        if( a === 'Yes'){
        return(Yes1)
        }
        if( a === 'No'){
        return(No1)
        }
        if( a === 'Yes '){
        return(Yes2)
        }
        if( a === 'No '){
        return(No2)
        }
        if( a === 'Neither'){
        return(Neither)
        }
        if( a === 'Flying'){
        return(Flying)
        }
        if( a === 'Being chased/fighting'){
        return(Chased)
        }
        if( a === 'Falling'){
        return(Falling)
        }
        if( a === 'Lost'){
        return(Lost)
        }
        if( a === 'Death'){
        return(Death)
        } 
        if( a === 'Birth'){
        return(Birth)
        }  
        if(a === 'Next'){
        return(Next)
        }
    }
    //function that builds the feedback list and turns selected button white
    const Happy = () => {
        const btn = document.querySelector("button1")
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === a1) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(a1)
        btn.style.color = "white"
        }
    }
    const Sad = () => {
        const btn = document.querySelector("button2")
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === a2) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(a2)
        btn.style.color = "white"
        }
    }
    const Loved = () => {
        const btn = document.querySelector("button3")
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === a3) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(a3)
        btn.style.color = "white"
        }
    }
    const Afraid = () => {
        const btn = document.querySelector("button4")
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === a4) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(a4)
        btn.style.color = "white"
        }
    }
    const Stressed = () => {
        const btn = document.querySelector("button5")
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === a5) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(a5)
        btn.style.color = "white"
        }
    }
    const Angry = () => {
        const btn = document.querySelector("button6")
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === a6) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(a6)
        btn.style.color = "white"
        }
    }
    const NOTA1 = () => {
        const btn = document.querySelector("buttonNOA1")
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === a7) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(a7)
        btn.style.color = "white"
        }
    }
    const NOTA2 = () => {
        const btn = document.querySelector("buttonNOA2");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === b7) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(b7)
        btn.style.color = "white"
        }
    }
    const NOTA3 = () => {
        const btn = document.querySelector("buttonNOA3");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === c7) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(c7)
        btn.style.color = "white"
        }
    }
    const NOTA4 = () => {
        const btn = document.querySelector("buttonNOA4");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === f7) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(f7)
        btn.style.color = "white"
        }
    }
    const Watched_TV = () => {
        const btn = document.querySelector("buttonb1");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === b1) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(b1)
        btn.style.color = "white"
        }
    }
    const Ate = () => {
        const btn = document.querySelector("buttonb2");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === b2) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(b2)
        btn.style.color = "white"
        }
    }
    const Drugs = () => {
        const btn = document.querySelector("buttonb3");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === b3) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(b3)
        btn.style.color = "white"
        } 
    }
    const Remedy = () => {
        const btn = document.querySelector("buttonb4");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === b4) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(b4)
        btn.style.color = "white"
        }
    }
    const Prayed = () => {
        const btn = document.querySelector("buttonb5");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === b5) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(b5)
        btn.style.color = "white"
        }
    }
    const Hanky = () => {
        const btn = document.querySelector("buttonb6");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === b6) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(b6)
        btn.style.color = "white"
        }
    }
    const Happy2 = () =>{
        const btn = document.querySelector("buttonc1");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === c1) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(c1)
        btn.style.color = "white"
        }
    }
    const Sad2 = () => {
        const btn = document.querySelector("buttonc2");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === c2) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(c2)
        btn.style.color = "white"
        }
    }
    const Loved2 = () => {
        const btn = document.querySelector("buttonc3");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === c3) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(c3)
        btn.style.color = "white"
        }
    }
    const Afraid2 = () => {
        const btn = document.querySelector("buttonc4");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === c4) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(c4)
        btn.style.color = "white"
        }
    }
    const Stressed2 = () => {
        const btn = document.querySelector("buttonc5");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === c5) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(c5)
        btn.style.color = "white"
        }
    }
    const Angry2 = () => {
        const btn = document.querySelector("buttonc6");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === c6) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(c6)
        btn.style.color = "white"
        }
    }
    const Yes1 = () => {
        const btn = document.querySelector("buttond1");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === d1) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(d1)
        btn.style.color = "white"
        }
    }
    const No1 = () => {
        const btn = document.querySelector("buttond2");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === d2) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(d2)
        btn.style.color = "white"
        }
    }
    const Yes2 = () => {
        const btn = document.querySelector("buttone1");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === e1) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(e1)
        btn.style.color = "white"
        }
    }
    const No2 = () => {
        const btn = document.querySelector("buttone2");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === e2) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(e2)
        btn.style.color = "white"
        }
    }
    const Neither = () => {
        const btn = document.querySelector("buttone3");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === e3) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(e3)
        btn.style.color = "white"
        }
    }
    const Flying = () => {
        const btn = document.querySelector("buttonf1");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === f1) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(f1)
        btn.style.color = "white"
        }
    }
    const Chased = () => {
        const btn = document.querySelector("buttonf2");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === f2) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(f2)
        btn.style.color = "white"
        }
    }
    const Falling = () => {
        const btn = document.querySelector("buttonf3");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === f3) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(f3)
        btn.style.color = "white"
        }
    }
    const Lost = () => {
        const btn = document.querySelector("buttonf4");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === f4) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(f4)
        btn.style.color = "white"
        }
    }
    const Death = () => {
        const btn = document.querySelector("buttonf5");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === f5) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(f5)
        btn.style.color = "white"
        }
    }
    const Birth = () => {
        const btn = document.querySelector("buttonf6");
        if(btn.style.color === "white"){
        btn.style.color = "grey"
        for( var i = 0; i < feedback.length; i++){ 
            if (feedback[i] === f6) {  
                feedback.splice(i, 1)
            }
        }
        }
        else{
        feedback.push(f6)
        btn.style.color = "white"
        }
    }
    const Next = () => {
        if(fun === Home) {
        setFun(B)
        setEvents(B1)
        setEvents2(B2)
        setEvents3(B3)
        setEvents4(B4)
        setEvents5(B5)
        setEvents6(B6)
        setEvents7(B7)     
        }
        if(fun === A) {
        setFun(B)
        setEvents(B1)
        setEvents2(B2)
        setEvents3(B3)
        setEvents4(B4)
        setEvents5(B5)
        setEvents6(B6)
        setEvents7(B7)   
        }
        if(fun === B) {
        setFun(C)
        setEvents(C1)
        setEvents2(C2)
        setEvents3(C3)
        setEvents4(C4)
        setEvents5(C5)
        setEvents6(C6)
        setEvents7(C7)
        }
        if(fun === C) {
        setFun(D)
        setEvents(D1)
        setEvents2(D2)
        }  
        if(fun === D) {
        setFun(E)
        setEvents(E1)
        setEvents2(E2)
        setEvents3(E3)
        }
        if(fun === E) {
        setFun(F)
        setEvents(F1)
        setEvents2(F2)
        setEvents3(F3)
        setEvents4(F4)
        setEvents5(F5)
        setEvents6(F6)
        setEvents7(F7)
        }  
        if(fun ===(F)) {
        setFun(G)
        setEvents(feedback)
        }
     
    }
    

    
return(
        
        
        <html lang="en">
        <head>
            <title>Tanessa Dreams</title>
            <meta charset="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/> 
            <link rel="icon" href="tanessadreams.ico"/>
            <link rel="stylesheet" href="https://use.typekit.net/qop2twf.css"/> 
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>    
        </head>
        <body>
            <h1>M<p1> Y </p1>M<p1> O O N </p1>D<p1> R E A M S</p1></h1>		
            <div>
            <quest>
                <Question question = {fun} width = {width} height = {height} A = {A} />
            </quest>
            </div>
            <img src = {url} alt="moon" width= '170' height='170' border='120'></img>
            <apply>
            <Apply width = {width} apply = {fun} F = {F} D = {D} E = {E} G = {G} A = {A} B = {B} C = {C}/>
            </apply>
            <div>
            <answer><Answer NOA4 = {NOA4} NOA3 = {NOA3} NOA2 = {NOA2} NOA1 = {NOA1} next1 = {next1} t = {fun} width = {width} height = {height} makeFun = {makeFun} events = {events} Next = {Next} events2 = {events2} events3 = {events3} events4 = {events4} events5 = {events5} events6 = {events6} events7 = {events7} moonImage = {moonImage}/></answer>
            </div>
        </body>
    
        <style global jsx>{`
            body {
            background: black;
            font-size:160px;
            }
            quest {
            color:grey; 
            font-family:Verdana;
            white-space: nowrap;  
            text-align:center;
            position: relative;
            top: -60px;
            }
            h1 {
            color:grey; 
            background-color: white;
            text-align: center;
            font-family: brother-1816-printed, sans-serif;
            font-weight: 20;
            font-style: italic;
            font-size: ${width*0.04}50%;
            font-color: white;
            border-radius: 225px 15px 225px 15px/15px 225px 15px 255px;
            position: relative;
            white-space: nowrap;
            }
            p1 {
            color:grey; 
            background-color: white;
            text-align: center;
            font-size: 80%;
            font-family: brother-1816-printed, sans-serif;
            font-weight: 20;
            white-space: nowrap;
            font-style: normal;
            font-color: white;
            padding: 1px 7px;
            border-radius: 225px 15px 225px 15px/15px 225px 15px 255px;
            }
            answer {
            color:grey; 
            background-color:black;
            font-family:Verdana;
            white-space: nowrap;
            margin: 0em;
            font-size: -50%;
            top: -80px;
            left: -21%;
            text-align:center;
            position: relative;
            font-size:40%; 
            }
            buttonNOA2 {
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonNOA3 {
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonNOA4 {
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            button1{ 
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            button2{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            button3{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            button4{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
    
            }
            button5{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            button6{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            button7{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonb1{ 
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonb2{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonb3{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonb4{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
    
            }
            buttonb5{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonb6{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonb7{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonNOA1 {
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
        
            buttonc1{ 
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonc2{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonc3{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonc4{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
    
            }
            buttonc5{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonc6{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttond1{ 
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttond2{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttond3{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttone1{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttone2{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttone3{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonf1{ 
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonf2{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonf3{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonf4{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
    
            }
            buttonf5{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            buttonf6{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            position: relative;
            }
            
            buttonN{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            border: none;
            position: relative;
            background-color: black;
            color: grey;
            }
            buttonN2{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            border: none;
            position: relative;
            background-color: black;
            color: grey;
            }
            buttonN3{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            border: none;
            position: relative;
            background-color: black;
            color: grey;
            }
            buttonN4{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            border: none;
            position: relative;
            
            color: grey;
            }
            buttonN5{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            border: none;
            position: relative;
            background-color: black;
            color: grey;
            }
            buttonN6{
            margin: ${ButMargin}px;
            font-size: ${ButFontSize}%;
            left: ${ButLeft}%;
            top: ${ButTop}px;
            display: grid;
            border: none;
            position: relative;
            background-color: black;
            color: grey;
            }
            img{
            position:relative;
            width: 20%;
            height: 20%;
            border: none;
            top: -130px;
            }`
        }</style> 
        </html>
);

    };
    export default Moon;