export default function Question(props) {
    var A = 'How did you feel before you went to sleep?'
    var B = 'What did you do before you fell asleep?'
    var C = 'How did you feel upon waking?'
    var D = 'Have you had this dream before?'
    var E = 'Did you enjoy this dream?'
    var F = 'Did you experience any of these in your dream?'
    var G = 'Results'
    if(props.question === A) {
        return(
            <quest 
            style={{
                fontSize: `${(props.width)*0.022}50%`,
                paddingLeft: '11%'
            }}>{props.question}</quest>
        )
    }
    if(props.question === B) {
        return(
            <quest style={{
                fontSize: `${(props.width)*0.022}50%`,
                paddingLeft: '14.5%'
            }}>{props.question}</quest>
        )
    }
    if(props.question === C) {
        return(
            <quest style={{
                fontSize: `${(props.width)*0.022}50%`,
                paddingLeft: '23%'
            }}>{props.question}</quest>
        )
    }
    if(props.question === D) {
       
        return(
            <quest style={{
                fontSize: `${(props.width)*0.022}50%`,
                paddingLeft: '20%'
            }}>{props.question}</quest>
        )
    }
    if(props.question === E) {
        return(
            <quest style={{
                fontSize: `${(props.width)*0.022}50%`,
                paddingLeft: '25%'
            }}>{props.question}</quest>
        )
    }
    if(props.question === F) {
        return(
            <quest style={{
                fontSize: `${(props.width)*0.022}50%`,
                paddingLeft: '7%'
            }}>{props.question}</quest>
        )
    }
    if(props.question === G) {
        return(
            <quest style={{
                fontSize: `${(props.width)*0.022}50%`,
                paddingLeft: '7%'
            }}></quest>
        )
    }
}
